declare global {
  interface Window {
    IS_APP: boolean;
  }
}

export {};

const isAnchor = (element: HTMLElement): element is HTMLAnchorElement =>
  element?.tagName.toLowerCase() === 'a';

const isTargetBlank = (element: HTMLElement): boolean =>
  element.getAttribute('target') === '_blank';

const getHref = (element: HTMLElement | null): string | null =>
  element?.getAttribute('href') || null;

const isInternalLink = (href: string): boolean => href?.includes(window.location.hostname) || false;

const isRelativeLink = (href: string): boolean => href?.startsWith('/') || false;

const openSelfLink = (e: MouseEvent, link: string) => {
  e.preventDefault();
  window.open(link, '_self');
};

document.addEventListener('click', (e: MouseEvent) => {
  const { IS_APP } = window;
  if (!IS_APP) return;

  const el = e.target as HTMLElement;
  if (!el) return;

  if (isAnchor(el)) {
    const href = getHref(el);
    if (!href) return;

    if (isTargetBlank(el) && (isInternalLink(href) || isRelativeLink(href))) {
      return openSelfLink(e, el.href);
    }
  }

  // If the element is not an anchor, check if it's a child of an anchor
  const closestAnchor = el.closest('a');
  if (!closestAnchor) return;

  const closestHref = getHref(closestAnchor);
  if (!closestHref) return;

  if (
    isTargetBlank(closestAnchor) &&
    (isInternalLink(closestHref) || isRelativeLink(closestHref))
  ) {
    openSelfLink(e, closestHref);
  }
});
